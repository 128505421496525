@media (width >= 1024px) {
  .btnConocePropuesta {
    cursor: pointer;
    background: none;
    border: none;
    justify-content: start;
    align-items: center;
    width: 44.9rem;
    height: 6.4rem;
    text-decoration: none;
    transition: color .2s ease-in-out;
    display: flex;
  }

  .btnConocePropuesta__circulo {
    background: var(--violet-dark-web-space);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 6.4rem;
    height: 6.4rem;
    transition: transform .2s ease-in;
    display: flex;
    position: relative;
  }

  .btnConocePropuesta__circulo img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .btnConocePropuesta p {
    color: #000;
    margin-left: 1.6rem;
    font-family: Akzidenz-Grotesk Pro Ext;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 3.072rem;
    transition: color .2s ease-in;
  }

  .btnConocePropuesta:hover .btnConocePropuesta__circulo {
    transform: rotate(45deg);
  }

  .btnConocePropuesta:hover p {
    color: var(--violet-dark-web-space);
  }
}
/*# sourceMappingURL=index.a0fe8683.css.map */
