


@media (min-width: 1024px) {
    .btnConocePropuesta {
        width: 44.9rem;
        height: 6.4rem;
        display: flex;
        align-items: center;
        justify-content: start; 
        border: none; 
        cursor: pointer;
        background: transparent;    
        transition: color 0.2s ease-in-out;
        text-decoration: none;
        
    }
    
    .btnConocePropuesta__circulo {
        width: 6.4rem;
        height: 6.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--violet-dark-web-space); 
        position: relative;
        transition: transform 0.2s ease-in;
    }
    
    .btnConocePropuesta__circulo img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .btnConocePropuesta p {
        color: #000000; 
        font-size: 2.4rem; 
        line-height: 3.072rem;
        font-weight: 400;
        transition: color 0.2s ease-in;
        margin-left: 1.6rem;
        font-family: "Akzidenz-Grotesk Pro Ext";
    }
    
    .btnConocePropuesta:hover .btnConocePropuesta__circulo{
        transform: rotate(45deg);
    }
    
    .btnConocePropuesta:hover p {
        color: var(--violet-dark-web-space);
    }
}

